import { graphql, Link } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import Masonry from "react-masonry-css";
import ContactSection from "../components/contact/ContactSection/ContactSection";
import HeroSection from "../components/HeroSection/HeroSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cleanImgBase, getSingleImageBySrc, Images } from "../utils/findImage";

interface ResidentialPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
    projectImagesFirst: Images;
    projectImagesSecond: Images;
    allIcons: Images;
  };
}

const ResidentialPage: React.FC<ResidentialPageProps> = ({
  location,
  data,
}) => {
  return (
    <Layout>
      <SEO
        title="Interior &amp; Exterior Painting Charlotte, NC"
        description="Transform your space into a luxury home. We provide high-quality interior and exterior painting in the Charlotte area. Contact us to get a free estimate."
        slug={location.pathname}
      />

      <HeroSection
        bgImage={data.bgImage.gatsbyImageData}
        heading="Home painting services"
      >
        Providing top quality painting services in Charlotte for over 15 years.
      </HeroSection>

      <section>
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-20 lg:items-center">
            <div className="w-full">
              <div className="relative">
                <StaticImage
                  src={"../images/projects/exterior-painting-6.jpg"}
                  alt="exterior painting"
                  objectFit="cover"
                  width={600}
                  height={600}
                  className="z-20"
                />

                <div className="absolute top-8 -left-8 max-h-full max-w-full w-[584px] h-[584px] z-0 overflow-hidden">
                  <div className="bg-[#f8f8fa] w-full h-full" />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-black mb-6">
                Transform your space into luxury home
              </h2>
              <p className="mb-4">
                We are professional painters with over 15 years of experience
                providing Charlotte, NC, and the surrounding area with the
                highest quality workmanship.
              </p>
              <p className="mb-4">
                We specialize in a wide range of interior and exterior painting
                services, including simple wall and trim repaints, exterior
                brick painting, luxury wallpaper, and high gloss finishes.
              </p>
              <p className="mb-4">
                We understand the importance of listening to our clients and
                figuring out what is perfect for their space. Once we've
                determined what is best for your home, we deliver an amazing
                transformation of your space with top-of-the-line long-lasting
                products.
              </p>
              <p>
                Contact us to get a free estimate and let us transform your home
                into a masterpiece.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <ServiceCard
              title="Interior painting"
              icon={getSingleImageBySrc("interior", data.allIcons)}
            >
              We can help you find a unique style to create a stunning, clean,
              and bright interior for your home.
            </ServiceCard>
            <ServiceCard
              title="Exterior painting"
              icon={getSingleImageBySrc("exterior", data.allIcons)}
            >
              We'll add a beautiful, fresh coat of paint to your walls to
              breathe new life into your home.
            </ServiceCard>
            <ServiceCard
              title="Kitchen cabinets"
              icon={getSingleImageBySrc("kitchen-cabinets", data.allIcons)}
              to="/kitchen-cabinets/"
            >
              We enhance your kitchen and give it a fresh new look with premium,
              long-lasting cabinet paint.
            </ServiceCard>
          </div>
        </div>
      </section>

      <section className="py-0">
        <div>
          <Masonry
            breakpointCols={{ default: 4, 640: 1, 1024: 2 }}
            className="col-span-full flex flex-wrap"
            columnClassName="px-0.5 bg-clip-padding"
          >
            {data.projectImagesFirst.nodes.map((node, index) => (
              <div key={`projectImage-${node.relativePath}`} className="mb-1">
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={cleanImgBase(node.base)}
                />
              </div>
            ))}
            {data.projectImagesSecond.nodes.map((node, index) => (
              <div key={`projectImage-${node.relativePath}`} className="mb-1">
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={cleanImgBase(node.base)}
                />
              </div>
            ))}
          </Masonry>
        </div>
      </section>

      <ContactSection>
        Contact us to get a free estimate and let us transform your home into a
        masterpiece.
      </ContactSection>
    </Layout>
  );
};

export default ResidentialPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/exterior-painting-1/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
    allIcons: allFile(filter: { relativeDirectory: { eq: "icons-dark" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 48, height: 48)
          original {
            src
          }
        }
        base
      }
    }
    projectImagesFirst: allFile(
      filter: {
        base: {
          in: [
            "exterior-painting-3.jpg"
            "exterior-painting-8.jpg"
            "interior-painting-6.jpg"
            "interior-painting-5.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
        base
      }
    }
    projectImagesSecond: allFile(
      filter: {
        base: {
          in: [
            "exterior-painting-2.jpg"
            "interior-painting-1.jpg"
            "interior-painting-2.jpg"
            "exterior-painting-6.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          original {
            src
          }
        }
        base
      }
    }
  }
`;

interface ServiceCardProps {
  icon?: IGatsbyImageData;
  title: string;
  to?: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  icon,
  title,
  to,
  children,
}) => {
  return (
    <div className="bg-white px-6 py-8 lg:px-10 lg:py-16">
      <div className="mb-5">
        {icon ? <GatsbyImage image={icon} alt={title} /> : null}
      </div>
      <div>
        <h3 className="text-2xl font-bold mb-4 lg:mb-7">
          {to ? <Link to={to}>{title}</Link> : title}
        </h3>
        <p>{children}</p>
      </div>
    </div>
  );
};
