import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

interface HeroSectionProps {
  bgImage: IGatsbyImageData;
  heading: string;
  small?: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  bgImage,
  heading,
  small = false,
  children,
}) => {
  return (
    <section
      className={`relative bg-primary-dark ${
        small ? "h-[20vh] min-h-[200px]" : "h-[40vh] min-h-[400px]"
      } py-0`}
    >
      <GatsbyImage
        image={bgImage}
        alt={heading}
        objectFit="cover"
        objectPosition="center center"
        className="h-full mix-blend-overlay"
      />

      <div className="absolute inset-center w-full max-w-xl text-center text-white px-4">
        <h1 className="text-3xl md:text-5xl font-bold text-white mb-4 ">
          {heading}
        </h1>
        {children ? (
          <h2 className="text-lg md:text-xl text-white leading-snug mb-0">
            {children}
          </h2>
        ) : null}
      </div>
    </section>
  );
};

export default HeroSection;
